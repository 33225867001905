import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
}
export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">


<path d="M5060 8444 c-920 -108 -1708 -593 -2222 -1364 -97 -146 -254 -453
-312 -610 -129 -354 -188 -685 -188 -1070 0 -396 51 -689 183 -1053 369 -1020
1268 -1775 2342 -1967 62 -11 202 -28 312 -37 172 -14 224 -15 385 -5 385 26
689 95 1025 234 365 151 686 368 980 663 249 249 425 494 580 809 220 446 315
855 315 1356 0 282 -27 503 -94 762 -104 402 -264 735 -517 1073 -466 623
-1132 1035 -1909 1180 -240 45 -636 58 -880 29z m298 -222 c9 -6 12 -63 10
-243 l-3 -234 143 -3 142 -3 0 -57 0 -57 -182 3 c-101 1 -198 5 -215 8 l-33 5
0 152 c0 84 3 216 7 295 l6 142 56 0 c31 0 62 -4 69 -8z m-305 -272 c25 -135
49 -266 52 -291 l7 -45 -63 -12 c-35 -6 -66 -8 -70 -4 -4 4 -19 77 -34 162
-33 189 -51 289 -64 355 -5 27 -7 52 -4 55 3 4 122 27 130 25 0 0 21 -110 46
-245z m927 211 c69 -16 160 -37 203 -46 65 -14 77 -20 72 -34 -4 -9 -9 -31
-12 -49 -3 -18 -9 -35 -14 -38 -5 -2 -46 4 -91 15 -46 11 -111 27 -145 34
l-61 15 -15 -60 c-11 -44 -12 -60 -4 -63 7 -2 62 -16 122 -30 61 -14 115 -27
121 -29 15 -6 -4 -106 -20 -106 -6 0 -65 14 -131 30 -66 16 -123 26 -126 22
-9 -11 -32 -113 -26 -118 4 -5 191 -51 274 -69 30 -6 32 -9 26 -38 -12 -63
-21 -78 -45 -72 -13 3 -110 25 -217 50 -107 25 -196 47 -199 49 -2 3 12 74 32
158 20 84 50 213 67 286 26 111 34 132 47 128 10 -3 73 -18 142 -35z m-1290
-101 c15 -11 36 -41 46 -65 17 -45 14 -69 -12 -131 -3 -6 16 -19 41 -28 52
-20 81 -52 96 -108 18 -70 -26 -145 -110 -187 -43 -22 -288 -109 -328 -117
l-28 -5 -93 268 c-51 147 -92 273 -92 279 0 7 10 14 23 17 12 2 76 24 142 47
66 24 136 47 155 51 53 12 127 2 160 -21z m1971 -127 c58 -29 159 -112 159
-131 0 -5 -18 -25 -39 -46 l-39 -38 -38 41 c-74 82 -178 120 -210 76 -8 -10
-14 -25 -14 -33 0 -8 45 -58 99 -111 109 -106 128 -141 110 -206 -12 -44 -59
-99 -99 -115 -51 -21 -148 -8 -221 31 -66 34 -179 137 -179 161 1 7 20 28 43
45 l42 33 15 -23 c46 -71 144 -137 203 -137 74 0 77 50 5 117 -108 99 -137
133 -149 173 -21 69 19 156 85 186 53 24 152 14 227 -23z m-2613 -212 c68 -35
117 -85 147 -152 68 -153 -5 -334 -168 -413 -121 -60 -244 -44 -341 45 -71 65
-100 130 -100 224 -1 139 90 255 242 312 54 20 166 12 220 -16z m3047 -53 l46
-43 -113 -130 c-140 -161 -148 -173 -148 -229 0 -39 5 -50 36 -80 84 -81 135
-59 298 128 65 75 121 136 125 136 3 -1 28 -20 54 -43 l48 -43 -92 -104 c-194
-222 -223 -244 -324 -244 -73 -1 -132 26 -194 86 -89 87 -113 190 -66 284 24
48 257 324 274 324 5 0 30 -19 56 -42z m-1450 -249 c432 -50 848 -248 1163
-553 371 -358 583 -813 622 -1332 36 -496 -130 -1021 -447 -1414 -72 -89 -254
-267 -338 -331 -739 -565 -1737 -568 -2482 -7 -187 140 -378 353 -506 565
-383 631 -391 1431 -23 2066 119 204 308 426 485 569 271 219 629 378 961 429
165 25 395 28 565 8z m-2125 -289 c123 -88 229 -165 234 -170 6 -6 -3 -28 -25
-60 -19 -27 -36 -50 -39 -50 -3 0 -65 44 -138 96 -74 53 -135 94 -137 92 -1
-2 26 -68 61 -147 63 -143 63 -144 46 -173 -14 -25 -22 -28 -47 -24 -16 3 -88
8 -160 12 l-130 6 132 -93 c73 -52 132 -98 133 -102 0 -13 -62 -97 -72 -97 -5
0 -115 75 -244 167 l-234 166 17 27 c48 72 37 68 158 59 61 -4 156 -11 212
-15 l103 -7 -80 177 c-43 98 -82 185 -85 195 -5 16 48 101 63 101 4 0 108 -73
232 -160z m4189 -100 c121 -190 143 -259 101 -328 -32 -52 -76 -74 -137 -68
l-53 5 0 -37 c0 -76 -69 -142 -148 -142 -55 0 -90 19 -139 74 -42 47 -203 297
-203 314 0 5 62 49 138 97 75 48 182 115 237 151 55 35 105 64 110 64 6 0 48
-59 94 -130z m-4753 -1275 c87 -57 83 -199 -7 -247 -77 -41 -165 -11 -200 67
-24 55 -18 103 19 147 49 58 129 72 188 33z m5040 0 c89 -58 83 -205 -10 -249
-80 -38 -163 -8 -198 70 -24 56 -18 100 19 145 50 59 129 73 189 34z m-4853
-964 c4 -18 5 -37 0 -41 -11 -10 -352 -104 -358 -98 -2 2 -7 22 -11 45 l-6 41
48 11 c27 6 103 27 169 46 66 18 126 33 134 32 8 -1 19 -17 24 -36z m4585 19
l24 -20 -22 -30 c-47 -66 -49 -140 -5 -140 19 0 32 14 65 70 46 80 83 105 136
96 19 -4 43 -19 58 -37 36 -42 36 -123 1 -194 -42 -88 -65 -103 -99 -66 -16
18 -16 21 14 63 38 53 50 117 26 137 -25 21 -41 8 -86 -68 -22 -39 -49 -75
-60 -81 -48 -26 -116 -7 -143 39 -33 56 -12 176 40 233 20 22 22 22 51 -2z
m-4530 -215 c18 -40 16 -47 -48 -175 -33 -66 -60 -122 -60 -124 0 -3 45 16
100 41 55 26 102 44 105 42 2 -3 11 -21 19 -40 l15 -35 -171 -77 c-94 -43
-172 -76 -173 -75 -1 2 -9 16 -18 33 -15 29 -13 32 49 157 35 71 60 128 55
128 -5 0 -48 -18 -96 -40 -48 -22 -90 -40 -94 -40 -11 0 -44 74 -34 78 4 2 80
36 168 77 88 40 163 74 166 74 3 1 11 -10 17 -24z m4395 -126 c12 -7 23 -13
25 -14 2 -2 -15 -40 -38 -85 -23 -45 -40 -86 -38 -92 2 -5 17 -16 34 -23 l31
-13 38 78 38 79 29 -15 c15 -8 28 -17 28 -21 0 -6 -41 -96 -61 -135 -8 -14 -2
-21 29 -37 l39 -20 43 90 c24 49 48 89 53 89 7 0 46 -18 55 -25 2 -2 -26 -62
-62 -134 l-66 -131 -170 85 -170 85 62 125 c35 69 66 125 71 125 5 0 18 -5 30
-11z m-4129 -386 l87 -137 -23 -18 c-29 -22 -45 -23 -52 0 -3 9 -17 30 -30 47
l-23 29 -123 -77 c-68 -43 -128 -76 -134 -74 -6 3 -19 18 -28 35 l-18 30 123
77 c67 43 124 80 127 82 2 2 -8 27 -24 55 l-29 51 24 18 c13 10 27 19 30 19 3
0 45 -62 93 -137z m3968 -9 c4 -32 1 -37 -27 -48 -35 -15 -72 -57 -81 -93 -9
-35 4 -72 35 -104 77 -76 201 -24 201 86 0 19 4 35 9 35 5 0 23 3 41 7 l33 6
-5 -55 c-13 -162 -174 -253 -304 -173 -136 85 -120 281 31 359 48 25 62 21 67
-20z m-3727 -285 l97 -101 -27 -27 -27 -26 -66 72 -67 72 -27 -20 c-16 -12
-28 -26 -28 -32 0 -6 26 -37 57 -69 l57 -58 -24 -25 -24 -26 -63 63 -63 62
-25 -24 c-14 -13 -25 -28 -25 -34 0 -6 30 -41 67 -78 l68 -68 -25 -25 -25 -25
-104 104 -104 104 134 131 c74 71 137 130 140 131 4 0 50 -46 104 -101z m3519
-146 c69 -67 126 -125 126 -129 0 -4 -12 -20 -26 -35 l-27 -28 -133 127 c-74
69 -134 130 -134 136 0 16 43 57 56 54 6 -2 68 -58 138 -125z m-203 -186 l53
-198 -30 -25 c-16 -13 -36 -23 -44 -21 -22 3 -350 181 -350 189 0 4 15 19 34
33 l33 25 124 -69 c68 -37 125 -67 127 -66 3 2 -18 85 -55 219 l-16 59 34 28
c19 15 35 27 35 26 1 -1 26 -91 55 -200z m-3054 92 c96 -70 123 -106 123 -165
0 -60 -54 -120 -115 -130 l-45 -6 0 -70 0 -69 -41 28 -40 28 6 62 c5 59 4 62
-24 82 -39 28 -46 27 -71 -14 -25 -41 -35 -43 -73 -12 l-28 22 108 153 c59 83
109 152 111 152 2 0 42 -28 89 -61z m292 -203 c24 -13 31 -29 61 -138 41 -152
45 -161 58 -128 6 14 26 60 45 103 l36 77 35 -17 c20 -9 36 -21 36 -27 0 -9
-137 -324 -145 -334 -1 -1 -16 3 -33 9 -28 10 -34 19 -53 82 -11 40 -30 104
-41 142 l-21 70 -23 -50 c-13 -27 -33 -71 -44 -97 -11 -27 -27 -48 -35 -48
-26 0 -66 23 -63 37 7 31 144 333 151 333 4 0 20 -6 36 -14z m2361 -29 c26
-16 60 -80 60 -113 0 -16 -7 -41 -15 -57 -14 -27 -14 -27 38 -57 29 -16 55
-30 60 -30 20 0 3 -19 -32 -35 l-39 -17 -57 30 c-55 30 -58 31 -89 15 -17 -8
-32 -17 -34 -18 -2 -2 6 -21 17 -44 12 -22 21 -44 21 -49 0 -8 -70 -46 -75
-40 -2 2 -39 78 -83 171 l-81 167 82 42 c45 23 96 45 112 49 35 9 90 2 115
-14z m-398 -161 c3 -29 2 -30 -87 -54 -49 -13 -91 -25 -92 -26 -4 -3 18 -76
22 -76 3 0 39 9 81 21 42 11 80 17 84 12 5 -4 10 -19 12 -32 3 -23 -3 -26 -77
-46 -44 -12 -80 -26 -80 -31 1 -18 17 -74 21 -74 3 0 46 12 96 26 50 14 94 21
98 17 4 -4 10 -20 14 -34 6 -26 5 -26 -134 -64 -77 -21 -142 -36 -143 -34 -10
13 -97 352 -92 357 11 12 260 79 267 73 4 -3 8 -19 10 -35z m-1367 -32 c81
-21 83 -23 73 -60 l-8 -31 -95 25 c-53 14 -97 23 -99 21 -2 -2 -7 -20 -11 -39
l-6 -36 76 -19 c42 -10 78 -23 81 -27 11 -17 -6 -58 -24 -58 -9 0 -46 9 -82
20 -35 11 -68 16 -71 12 -4 -4 -10 -23 -14 -42 l-6 -35 91 -24 c49 -13 93 -27
95 -32 10 -15 -7 -59 -22 -59 -7 0 -72 16 -144 36 -91 26 -129 41 -125 50 6
15 96 342 96 348 0 3 30 -4 68 -15 37 -11 94 -26 127 -35z m958 -53 c10 -10 0
-71 -11 -71 -4 0 -24 5 -44 10 -68 19 -138 -12 -122 -54 6 -15 23 -21 99 -32
90 -12 135 -49 135 -111 0 -81 -121 -139 -238 -115 -31 6 -59 15 -63 19 -4 5
-1 23 6 41 13 30 17 32 36 21 29 -15 136 -14 155 2 8 7 14 22 12 33 -3 18 -16
24 -92 38 -98 20 -113 28 -133 76 -27 66 17 130 103 151 43 11 144 5 157 -8z
m-604 -1 c63 -6 117 -12 119 -14 2 -3 1 -19 -3 -37 l-7 -32 -59 7 c-51 5 -59
3 -59 -11 0 -9 -5 -80 -12 -156 l-11 -139 -39 5 c-22 3 -42 8 -45 10 -2 3 1
69 7 147 14 162 15 160 -62 160 -41 0 -48 3 -48 19 0 57 13 59 219 41z"/>
<path d="M4445 7943 c-38 -14 -71 -26 -72 -27 -5 -5 39 -126 45 -126 4 1 45
15 92 33 66 25 88 38 98 59 10 22 10 32 1 50 -24 47 -61 49 -164 11z"/>
<path d="M4556 7730 c-49 -16 -93 -33 -97 -37 -9 -9 27 -133 38 -133 20 0 188
68 204 83 19 18 25 62 11 83 -8 12 -46 34 -59 34 -4 -1 -48 -14 -97 -30z"/>
<path d="M3825 7601 c-184 -83 -106 -368 95 -349 154 15 220 187 117 305 -56
63 -134 80 -212 44z"/>
<path d="M4845 7034 c-11 -2 -45 -9 -75 -15 -130 -26 -282 -141 -347 -264 -66
-126 -63 -60 -63 -1361 0 -1283 -2 -1224 57 -1343 37 -74 150 -187 230 -229
113 -59 140 -62 749 -62 350 0 572 4 610 11 198 36 383 215 424 412 7 37 10
408 8 1242 l-3 1190 -22 57 c-65 170 -188 287 -363 344 -50 16 -106 18 -620
20 -311 1 -574 0 -585 -2z m1205 -252 c61 -32 106 -77 138 -137 l27 -50 0
-1195 0 -1195 -25 -49 c-14 -27 -47 -68 -74 -92 -84 -76 -73 -75 -737 -72
-570 3 -585 3 -629 24 -63 30 -126 98 -150 161 -20 53 -20 79 -20 1222 0 1335
-8 1225 90 1323 91 91 61 87 745 85 l590 -2 45 -23z"/>
<path d="M5170 6675 c-7 -8 -10 -25 -6 -40 l6 -25 224 0 c188 0 226 2 236 15
10 12 10 21 2 40 l-12 25 -219 0 c-181 0 -221 -3 -231 -15z"/>
<path d="M4947 6039 c-15 -9 -17 -28 -17 -153 0 -115 3 -146 15 -156 8 -7 22
-10 30 -6 12 4 15 25 15 104 0 54 3 102 7 105 3 4 17 -2 29 -14 27 -25 58 -22
62 6 2 14 -14 35 -50 66 -68 58 -70 59 -91 48z"/>
<path d="M5848 6039 c-16 -6 -31 -23 -37 -41 -7 -21 -11 -230 -11 -639 l0
-609 145 0 145 0 0 628 c0 738 14 666 -129 669 -47 1 -98 -2 -113 -8z"/>
<path d="M4807 6034 c-4 -4 -7 -52 -7 -106 0 -54 -2 -98 -5 -98 -3 0 -16 9
-28 20 -26 25 -51 22 -55 -6 -4 -26 100 -127 126 -122 15 3 17 19 17 158 0
148 -1 155 -20 158 -12 2 -24 0 -28 -4z"/>
<path d="M5462 5727 c-22 -23 -22 -24 -22 -500 l0 -477 145 0 145 0 0 468 c0
448 -1 468 -20 500 l-20 32 -103 0 c-95 0 -105 -2 -125 -23z"/>
<path d="M5095 5505 l-25 -24 0 -366 0 -365 145 0 145 0 0 365 0 366 -25 24
c-22 23 -32 25 -120 25 -88 0 -98 -2 -120 -25z"/>
<path d="M4732 5327 c-22 -23 -22 -30 -22 -300 l0 -277 140 0 140 0 0 279 0
280 -26 20 c-23 18 -41 21 -119 21 -83 0 -94 -2 -113 -23z"/>
<path d="M5325 4446 c-17 -7 -45 -27 -62 -45 -89 -88 -61 -246 52 -301 143
-69 301 55 271 213 -8 43 -68 113 -113 132 -43 18 -108 19 -148 1z"/>
<path d="M7548 6959 c-27 -17 -48 -37 -48 -43 0 -26 96 -151 122 -160 39 -14
78 14 78 56 0 30 -82 178 -98 178 -4 0 -28 -14 -54 -31z"/>
<path d="M7338 6827 c-26 -18 -48 -35 -48 -40 0 -4 25 -45 55 -91 62 -94 92
-114 138 -90 37 20 44 55 19 102 -37 72 -93 152 -105 151 -7 0 -33 -15 -59
-32z"/>
<path d="M3868 3447 l-36 -53 31 -21 c76 -50 107 -50 133 -1 14 27 14 32 0 53
-14 21 -77 75 -87 75 -2 0 -20 -24 -41 -53z"/>
<path d="M6468 3239 l-47 -21 27 -57 c15 -31 28 -58 29 -59 1 -2 26 9 55 25
45 24 53 33 56 60 2 22 -3 38 -17 52 -26 26 -44 26 -103 0z"/>
</g>
</svg>

		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
